import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image";

const ServicesPage = () => {

    const data = useStaticQuery(graphql`
        query {
          membership_exclusive_partnerships: file(relativePath: { eq: "membership-exclusive-partnerships.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 411, maxHeight: 360) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          membership_turnkey_service: file(relativePath: { eq: "membership-turnkey-service.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 411, maxHeight: 360) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          membership_tailored_experiences: file(relativePath: { eq: "membership-tailored-experiences.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 411, maxHeight: 360) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      `);

    return (
        <Layout>
            <SEO title="Membership"/>
            <div className="container">

                <div className="opening">
                    <h1>Membership</h1>
                    <p>
                        As an exclusive member of Lux Delux, our turnkey concierge services will connect you to an
                        expertly curated network of the best dining, nightlife, travel, and entertainment destinations
                        around the globe. From chef’s-table seating at the buzziest restaurants to au courant nightlife
                        and exclusive entree to red-carpet galas, Lux Delux has it on lock.
                    </p>
                </div>

                <div className="row vertical-block">

                    <Img objectFit="cover" fluid={data.membership_exclusive_partnerships.childImageSharp.fluid}
                         className="image"/>
                    <div className="vertical-center">
                        <h3>Exclusive Partnerships</h3>
                        <p>
                            Tapping into an elite network of first-class global partners, Lux Delux connects our members
                            with the top establishments across a wide array of travel, hospitality, and entertainment.
                        </p>
                    </div>

                </div>
                <div className="row vertical-block">

                    <Img objectFit="cover" fluid={data.membership_turnkey_service.childImageSharp.fluid}
                         className="image"/>
                    <div className="vertical-center">
                        <h3>Turnkey Service</h3>
                        <p>
                            Our skilled, committed team of lifestyle professionals work around the clock to make your
                            dreams a reality. From ticketing and payment to travel arrangements and dining reservations,
                            we take care of everything so you can focus on the things that matter.
                        </p>
                    </div>
                </div>

                <div className="row vertical-block">

                    <Img objectFit="cover" fluid={data.membership_tailored_experiences.childImageSharp.fluid}
                         className="image"/>
                    <div className="vertical-center">
                        <h3>Tailored Experiences</h3>
                        <p>
                            Whatever it is you have in mind, Lux Delux has the expertise to execute unique, bespoke
                            experiences for five or five hundred.
                        </p>
                    </div>

                </div>

            </div>
        </Layout>
    )
}

export default ServicesPage
